import { useNavigate } from "react-router-dom";
import { metricsQueries, useMetricsService } from "../../../services/metricsService";
import { useQuery } from "react-query";
import { useState } from "react";

export function useAssetInfoWindowHook({asset}){
    const metricsService = useMetricsService();
    const navigate = useNavigate();
    const [showRegisterModal, setShowRegisterModal] =useState(false);

    const {
        data : metricsData,
        isLoading : isLoadingMetrics,
        isRefetching : isRefetchingMetrics,
        refetch : refetchMetrics
    } = useQuery(metricsQueries.GET_BY_ID, ()=>metricsService.getMetricsById({id: asset.id}));


    return{
        chats: {
            metricsData,
            isLoading: isLoadingMetrics || isRefetchingMetrics,
            refetchMetrics
        },
        general: {
            navigate,
            setShowRegisterModal,
            showRegisterModal
        }
    }
}