import { useEffect, useRef, useState } from "react";
import { sectionsQueries, useSectionsService } from "../../../../services/sectionService";
import { useMutation, useQuery } from "react-query";
import { anomalieSchema } from "../../../../models/schemas/anomalieSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';

import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import Utils from "../../../../utils/utils";
import { baseUrl } from "../../../../service/api";
import { queryClient } from "../../../../utils/query-client";
import { metricsQueries } from "../../../../services/metricsService";
import { useExecutionsService } from "../../../../services/executionService";
import { schemaQueries, useSchemaService } from "../../../../services/schemasService";
import { executionSchema } from "../../../../models/schemas/executionSchema";
import { getFromIndexedDB, saveToIndexedDB } from "../../../../service/indexedDB";
import { blobToArrayBuffer, generateRandomId } from "../../../../utils/functions";
import axios from "axios";
import { assetsQueries, useAssetsService } from "../../../../services/assetService";

export function useExecutionHook({ section, setOpacity, onClose, fkAssetId }) {
    const tabs = ['FORM', 'SELECTAREA', 'PHOTOS', 'SUCCESS'];
    const [tab, setTab] = useState(tabs[0]);
    const [refresh, setRefresh] = useState(true);
    const [refreshAnomaly, setRefreshAnomaly] = useState(true);
    const sectionService = useSectionsService();
    const assetService = useAssetsService();
    const schemaService = useSchemaService();
    const executionService = useExecutionsService();
    const orbitControls = useRef();
    const orbitControlsAnomaly = useRef();
    const [highlightedObject, setHighlightedObject] = useState(null);
    const [highlightedObjectAnomaly, setHighlightedObjectAnomaly] = useState(null);
    const colors = ['#00ff00', '#ff0000', '#00ff00', '#ff0000'];
    const [objects, setObjects] = useState([]);
    const [objectsAnomaly, setObjectsAnomaly] = useState([]);
    const [sphere, setSphere] = useState(null);
    const [sphereAnomaly, setSphereAnomaly] = useState(null);
    const [progress, setProgress] = useState(0);
    const [progressAnomaly, setProgressAnomaly] = useState(0);
    const [size, setSize] = useState();
    const camera = useRef();
    const cameraAnomaly = useRef();
    const [scaleFactor, setScaleFactor] = useState();
    const [selectedAnomalies, setSelectedAnomalies] = useState([]);

    const { mutate, isLoading } = useMutation((payload) => executionService.createExecutions({ payload, onSuccess }));
    const executionForm = useForm({ resolver: zodResolver(executionSchema) });

    const watchFkSectionId = executionForm.watch('fkSectionsId');
    const watchMedias = executionForm.watch('medias');
    const watchArea = executionForm.watch('area');

    useEffect(() => {
        executionForm.setValue('internalId', generateRandomId());
    }, []);

    useEffect(() => {
        if (parseFloat(watchArea) > parseFloat(section.totalArea)) {
            executionForm.setError('area', { message: 'Não pode ser maior que a área da sessão' })
        }
    }, [watchArea]);

    const {
        data: sectionsData,
        isLoading: isLoadingSections,
        isRefetching: isRefetchingSections,
        refetch: refetchSections
    } = useQuery(sectionsQueries.GET_ALL_OPTIONS, () => sectionService.getAllSectionsOptions({ search: '', page: 1 }));

    const {
        data: assetsData,
        isLoading: isLoadingAssets,
        isRefetching: isRefetchingAssets,
        refetch: refetchAssets
    } = useQuery(assetsQueries.GET_BY_ID, () => assetService.getAssetsById({ id: fkAssetId || section.fkAssetId }));

    const {
        data: selectedSection,
        isLoading: isLoadingSelectedSection,
        isRefetching: isRefetchingSelectedSection,
        refetch: refetchSelectedSection
    } = useQuery(sectionsQueries.GET_BY_ID, () => sectionService.getSectionsById({ id: watchFkSectionId || section.id }));

    const {
        data: schemasData,
        isLoading: isLoadingSchemas,
        isRefetching: isRefetchingSchemas,
        refetch: refetchSchemas
    } = useQuery(schemaQueries.GET_ALL_OPTIONS, () => schemaService.getAllSchemaOptions({ search: '', page: 1 }));

    const sectionOptions = section ? [{ key: section.id, value: section.name }] : sectionsData;

    function onSuccess() {
        setTab(tabs[4]);
        queryClient.refetchQueries(metricsQueries.GET_BY_SECTION_ID);
        queryClient.refetchQueries(sectionsQueries.GET_BY_ID);
        setTimeout(() => {
            onClose();
        }, 3000);
    }


    function validateForm() {
        const requiredFields = ["internalId", "date", "area", "fkPaintingSchemaId", "lifespan", "activityDurationDaysEffective", "totalBilling"];
        const isValid = requiredFields.every(field => executionForm.getValues(field));
        if (isValid) {
            setTab(tabs[1]);
            setOpacity(true);
        } else {
            executionForm.trigger(requiredFields);
        }
    }

    function onSubmit(payload) {
        mutate({ 
            ...payload, 
            fkSectionsId: watchFkSectionId ?? section.id ,
            resolveAnomalies: selectedAnomalies.map(anomaly=>({id: anomaly}))
        });
    }

    function handleObjectClickAnomaly(event, anomaly) {
        if(selectedAnomalies.includes(anomaly.id)){
            setSelectedAnomalies(selectedAnomalies.filter(an=>an!==anomaly.id))
        }else{
            setSelectedAnomalies([...selectedAnomalies, anomaly.id]);
        }
    }


    function handleObjectClick(event) {
        const intersects = event.intersections;
        if (intersects?.length > 0) {
            const intersect = intersects[0];
            const { point } = intersect;
            if (sphere) {
                sphere.position.copy(point);
                executionForm.setValue('position', JSON.stringify(sphere.position));
            } else {
                const objectArea = size.x * size.y; // Área do objeto

                const anomalyArea = (parseFloat(watchArea) * objectArea) / parseFloat(selectedSection.totalArea); // Área da anomalia
                const sphereRadius = 200 * scaleFactor; // Raio da esfera baseado na área da anomalia


                const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
                const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0.5 });
                const newSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                newSphere.position.copy(point);


                setObjects((prev) => {
                    if (prev.find(object => object.section === `sphere${prev.length - 1}`)) {
                        return prev;
                    }
                    setSphere(newSphere);
                    return [...prev, { section: `sphere${prev.length}`, object: newSphere }];
                });
                executionForm.setValue('position', JSON.stringify(newSphere.position));
            }
        }
    }


    useEffect(() => {
        if (selectedSection?.reference3d && assetsData?.reference3d) {

            Utils.clear3DObjects(objects, setObjects);

            const tempObjects = [];

            if (selectedSection?.reference3d?.section3d?.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const relativePaths = section.reference3d?.section3d.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        let arrayBuffer;

                        const cachedDataBlob = await getFromIndexedDB(cacheKey, `home-${assetsData?.reference3d.fkAsset3DItemId}`);

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${selectedSection?.reference3d?.section3d?.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgress((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            arrayBuffer = response.data;
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer], `home-${assetsData?.reference3d.fkAsset3DItemId}`));
                        }

                        if (arrayBuffer) {

                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            let size = new THREE.Vector3()
                            setSize(size);
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            setScaleFactor(scaleFactor);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: "#cccccc" });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            tempObjects.push(
                                { section: selectedSection.reference3d.section3d, object: stlMesh },
                            );

                            setObjects(tempObjects);

                            setRefresh(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${selectedSection?.reference3d?.section3d?.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [selectedSection, assetsData]);

    useEffect(() => {
        if (selectedSection?.reference3d) {

            Utils.clear3DObjects(objectsAnomaly, setObjectsAnomaly);

            const tempObjects = [];

            if (selectedSection?.reference3d?.section3d?.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const relativePaths = section.reference3d?.section3d.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        let arrayBuffer;

                        const cachedDataBlob = await getFromIndexedDB(cacheKey, `home-${assetsData?.reference3d.fkAsset3DItemId}`);

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${selectedSection?.reference3d?.section3d?.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgressAnomaly((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            arrayBuffer = response.data;
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer], `home-${assetsData?.reference3d.fkAsset3DItemId}`));
                        }

                        if (arrayBuffer) {

                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            let size = new THREE.Vector3()
                            setSize(size);
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            setScaleFactor(scaleFactor);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: "#cccccc" });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            tempObjects.push(
                                { section: selectedSection.reference3d.section3d, object: stlMesh },
                            );

                            if (section.anomalies) {
                                section.anomalies.forEach(anomaly => {
                                    if (!anomaly.isResolved) {
                                        let { position } = anomaly;
                                        if (position.includes('\"x\":')) {
                                            position = JSON.parse(position);
                                            const objectArea = size.x * size.y; // Área do objeto

                                            const anomalyArea = (parseFloat(anomaly.area) * objectArea) / parseFloat(section.totalArea); // Área da anomalia
                                            const sphereRadius = 200 * scaleFactor
                                            // const sphereRadius = sphereRadius / Math.sqrt(objectArea / Math.PI) / 8; // Fator de escala baseado na área do objeto

                                            const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
                                            const sphereMaterial = new THREE.MeshBasicMaterial({ color: selectedAnomalies?.includes(anomaly.id) ? 0x00ff00 : 0xff0000, transparent: true, opacity: 0.5 });
                                            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

                                            sphere.position.set(position.x, position.y, position.z);

                                            tempObjects.push({ anomaly, object: sphere, type:'anomaly'});
                                        }
                                    }
                                });
                            }

                            setObjectsAnomaly(tempObjects);

                            setRefreshAnomaly(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${selectedSection?.reference3d?.section3d?.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [selectedSection, selectedAnomalies]);

    useEffect(() => {
        if (watchFkSectionId && tab === tabs[1]) refetchSelectedSection();
    }, [watchFkSectionId, tab]);

    return {
        form: {
            mutate,
            isLoading,
            executionForm,
            watchMedias,
            onSubmit,
            validateForm,
        },
        general: {
            tab,
            tabs,
            setTab
        },
        selectedSection: {
            selectedSection,
            isLoading: isLoadingSelectedSection || isRefetchingSelectedSection,
            refetchSelectedSection
        },
        sections: {
            refetchSections,
            isLoading: isLoadingSections || isRefetchingSections,
            sectionOptions
        },
        schemas: {
            schemasData,
            isLoading: isLoadingSchemas || isRefetchingSchemas,
            refetchSchemas
        },
        config3d: {
            orbitControls,
            objects,
            highlightedObject,
            colors,
            handleObjectClick,
            sphere,
            camera,
            progress,
            isLoading: refresh || isLoadingAssets || isRefetchingAssets
        },
        config3dAnomaly: {
            orbitControlsAnomaly,
            objectsAnomaly,
            highlightedObjectAnomaly,
            handleObjectClickAnomaly,
            sphereAnomaly,
            cameraAnomaly,
            progressAnomaly,
            isLoadingAnomaly: refreshAnomaly
        }
    };
}
