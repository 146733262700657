import { styled } from 'styled-components';

export const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: var(--grey-light-color) !important;

    .info{
        position: fixed;
        top: 20px;
        width: calc(100vw - 480px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2999 !important;

        span{
            padding: 12px 16px;
            background-color: var(--white-color);
            border-radius: 8px;
            box-shadow: 4px 4px 4px #00000055;
        }
    }

    
`;

export const UpdateAnomaliePositionContainer = styled.div`
    position: relative;
    height: 100vh;
    overflow: scroll;

    img{
        position: absolute;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }
    
`;

export const SVGContainer = styled.div`
    width: 74vw;
    height: 85vh;
    margin: 3%;
    position: relative;
    
    .newLoud{
        z-index: 3000;
    }

    ._3d{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2999;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .progress-bar{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 14px;
        height: 100vh;
        justify-content: center;
        div{
            height: 80px;
        }
    }
`;


export const PinStatus = styled.div`
    position: absolute;
    top: ${props=>props.position.y-50}px;
    left: ${props=>props.position.x+50}px;
    cursor: pointer;
    z-index: 3000;

    .circle-status{
        width: 0.35rem;
        height: 0.35rem;
        background-color: ${props=>props.color};
        border-radius: 1rem;
        border: 0.15rem solid var(--grey-dark-color);
    }

    .border-status{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        background-color: var(--grey-medium-color);
        border-radius: 1rem;
        margin-top: 0.15rem;
    }
`;  

export const ButtonContainer = styled.div`
    position: absolute;
    bottom: 2rem;
    left: 2rem;
`;

export const AnomalieContainer = styled.div`
    background: var(--white-color) !important;
    width: 480px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .contentContainer{
        width: 420px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .row{
                display: flex;
                width: 100%;
                gap: 5%;
                overflow: scroll;
                align-items: center;

                button{
                    height: 32px;
                    width: 100% !important;
                    margin: 0 !important;
                }
            }

        .content{
            width: 90%;
            background-color: var(--grey-light-color) !important;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 5%;

            

            .itemRow{
                width: 90%;
                background-color: var(--white-color);
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 3% 5%;
                border: 1px solid "#E6E4E0";
            }

            .itemCol{
                width: 100%;
                background-color: var(--white-color);
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 3% 5%;
                border: 1px solid "#E6E4E0";
            }
        }
    }
`;