import { StatusTagIcon } from "../../../utils/iconsApp";
import { FontGreyDarkMediumTitle, FontGreyDarkRegular, FontGreyMediumSemiMedium } from "../../../utils/typografyApp";
import { StatusContainer, AssetInformationContainer } from "./styled";

import default_asset from "../../../assets/asset_1.jpg";
import { useAssetInformationHook } from "./hook";
import { Loading } from "../Loading";
import { Canvas } from "react-three-fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";


const key = Math.random();

const AssetInformation = ({ asset, reports = false }) => {

    const hook = useAssetInformationHook({section : asset});

    return (
        <AssetInformationContainer>
            <FontGreyDarkMediumTitle>{asset.name}</FontGreyDarkMediumTitle>
            <StatusContainer>
                <img src={StatusTagIcon[asset.status]} alt="Tag do status do Ativo" />
                <FontGreyMediumSemiMedium>{asset.code}</FontGreyMediumSemiMedium>
            </StatusContainer>
            {asset.fkAssetId && asset?.reference3d?.section3d?.location ?
                <div className="local_3d">
                    {(hook.section.isLoading &&  hook.config3d?.objects?.lenght === 0) && (
                        <div className="progress-bar">
                            <Loading />
                            <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                        </div>
                    )}
                    <Canvas key={key}>
                        <ambientLight intensity={0.5} />
                        <directionalLight
                            position={[20, 10, 10]}
                            castShadow
                            shadow-mapSize-width={1024}
                            shadow-mapSize-height={1024}
                            shadow-camera-far={50}
                            shadow-camera-left={-10}
                            shadow-camera-right={10}
                            shadow-camera-top={10}
                            shadow-camera-bottom={-10}
                        />
                        <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[0.7, 0.01, 1.3]} />
                        <OrbitControls ref={hook.config3d.orbitControls} enableZoom={false} enableRotate={false} enableDamping={false} enablePan={false} />
                        {hook.config3d.objects?.map((object, index) => (
                            object.object ?
                                <primitive
                                    key={index}
                                    object={object.object}
                                    onClick={(e) => {
                                        if (object.anomaly) {
                                            hook.general.setAnomalieWindow({
                                                isOpen: true,
                                                position: { x: e.pageX, y: e.pageY },
                                                content: object.anomaly
                                            });
                                        }
                                    }}

                                /> :
                                <></>
                        ))}
                    </Canvas>
                </div>
                :
                <img
                    className="img-asset"
                    src={
                        asset?.section ?
                            asset.section.media?.path :
                            asset.media?.path ??
                            default_asset
                    }
                    alt="Imagem do ativo"
                />
            }

        </AssetInformationContainer>
    );
}

export default AssetInformation;