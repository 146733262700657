import { useEffect, useState } from "react";
import { anomaliesQueries, useAnomaliesService } from "../../../../../services/anomalieService";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

export function useAnomaliesHook() {
    const anomaliesService = useAnomaliesService();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        const handlePopState = () => {
            if (location.pathname === "/anomalies") {
                navigate("/admin");
            }
        };

        // Detecta mudanças no histórico
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };}
    );

    const {
        data: anomaliesData,
        isLoading: isLoadingAnomalies,
        isRefetching: isRefetchingAnomalies,
        refetch: refetchAnomalies
        //TODO: trocar a paginacao
    } = useQuery(anomaliesQueries.GET_ALL, () => anomaliesService.getWithoutPositionAnomalies({ page: 1, search }));


    return {
        anomalies: {
            anomaliesData,
            isLoading : isLoadingAnomalies | isRefetchingAnomalies,
            refetchAnomalies,
            setSearch
        },
        navigate

    }
}