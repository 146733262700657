import NewRegisterModal from "../../../../components/Modals/NewRegisterModal";
import { Main } from "../../../../main-styled";
import { UpdateAnomaliePositionContainer, SVGContainer, PageContainer, AnomalieContainer } from './styled';
import { useUpdateAnomaliePositionHook } from './hook';
import { useEffect } from "react";
import { Loading } from "../../../../components/Loading";
import { FontGreyDarkFullBold, FontGreyDarkRegular, FontGreyDarkSemiBold, FontRedBold } from "../../../../../utils/typografyApp";
import { Canvas } from "react-three-fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../../utils/storeUtils";
import PrimaryButton from "../../../../components/PrimaryButton";
import BackButton from "../../../../components/BackButton";
import SwitchButton from "../../../../components/SwitchButton";
import image from '../../../../../assets/anomalie_1.png'
import SecondaryButton from "../../../../components/SecondaryButton";
import { formatDate } from "date-fns";
import { FormProvider } from "react-hook-form";
import { baseUrl } from "../../../../../service/api";

const key = Math.round();

const UpdateAnomaliePositionPage = ({ setMenuType, setShowDashboard }) => {
    const hook = useUpdateAnomaliePositionHook();

    console.log(hook.anomaly?.anomalyData?.medias)

    useEffect(() => {
        setMenuType("SECTION-PAGE");
        setShowDashboard();
    }, []);

    return (
        <FormProvider {...hook.form.anomalieForm}>
            <form onSubmit={hook.form.anomalieForm.handleSubmit(hook.form.onSubmit)}>
                <Main>
                    <PageContainer>
                        <div className="info">
                            <span>Clique para marcar a posição da anomalia</span>
                        </div>
                        {hook.general.showRegisterModal &&
                            <NewRegisterModal
                                setOpacity={hook.general.setOpacity}
                                onClose={() => hook.general.setShowRegisterModal(false)}
                                section={hook.section?.sectionData}
                            />
                        }

                        <UpdateAnomaliePositionContainer>
                            <SVGContainer>

                                <div className="_3d">
                                    {hook.section.isLoading && (
                                        <div className="progress-bar">
                                            <Loading />
                                            <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                                        </div>
                                    )}
                                    <Canvas key={key}>
                                        <ambientLight intensity={0.5} />
                                        <directionalLight
                                            position={[20, 10, 10]}
                                            castShadow
                                            shadow-mapSize-width={1024}
                                            shadow-mapSize-height={1024}
                                            shadow-camera-far={50}
                                            shadow-camera-left={-10}
                                            shadow-camera-right={10}
                                            shadow-camera-top={10}
                                            shadow-camera-bottom={-10}
                                        />
                                        <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[-2, 1, 1]} />
                                        <OrbitControls ref={hook.config3d.orbitControls} />
                                        {hook.config3d.objects?.map((object, index) => (
                                            object.object ?
                                                <primitive
                                                    key={index}
                                                    object={object.object}
                                                    onDoubleClick={hook.config3d.handleObjectClick}

                                                /> :
                                                <></>
                                        ))}
                                    </Canvas>
                                </div>

                            </SVGContainer>
                        </UpdateAnomaliePositionContainer>


                        <BackButton onClick={() => hook.general.navigate(`/anomalies`)} />
                        <AnomalieContainer>
                            <div className="contentContainer">
                                <div className="content">
                                    <FontGreyDarkFullBold>{hook.section?.sectionData?.name} </FontGreyDarkFullBold>
                                    <div className="itemRow">
                                        <FontGreyDarkRegular>Área degradada</FontGreyDarkRegular>
                                        <FontGreyDarkSemiBold>{hook.anomaly?.anomalyData?.area} m²</FontGreyDarkSemiBold>
                                    </div>

                                    <div className="row">
                                        <div className="itemCol">
                                            <FontGreyDarkRegular>ID</FontGreyDarkRegular>
                                            <FontGreyDarkSemiBold>#{hook.anomaly?.anomalyData?.internalId}</FontGreyDarkSemiBold>
                                        </div>
                                        <div className="itemCol">
                                            <FontGreyDarkRegular>Data</FontGreyDarkRegular>
                                            <FontGreyDarkSemiBold>{formatDate(hook.anomaly?.anomalyData?.date || new Date(), 'dd/MM/yyyy')}</FontGreyDarkSemiBold>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="itemCol">
                                            <FontGreyDarkSemiBold>{hook.anomaly?.anomalyData?.defectType?.name}</FontGreyDarkSemiBold>
                                        </div>
                                        <div className="itemCol">
                                            <FontRedBold>{hook.anomaly?.anomalyData?.degradationDegree?.name}</FontRedBold>
                                        </div>
                                    </div>

                                    <div className="itemRow">
                                        <FontGreyDarkRegular>Falha Prematura</FontGreyDarkRegular>
                                        <SwitchButton active={hook.anomaly?.anomalyData?.prematureFailure} />
                                    </div>

                                    <div className="row">
                                        {hook.anomaly?.anomalyData?.medias?.map(media => (
                                            <img src={`${baseUrl}/${media.path}`} />
                                        ))}

                                    </div>
                                </div>

                                <div className="row">
                                    <SecondaryButton>Voltar</SecondaryButton>
                                    <PrimaryButton type={'submit'} disabled={!hook.anomaly?.watchPosition || hook.form.isLoading}> {hook.form.isLoading ? 'Salvando...' : 'Salvar'}</PrimaryButton>
                                </div>
                            </div>
                        </AnomalieContainer>
                    </PageContainer>
                </Main >
            </form>
        </FormProvider>

    );
}



export default connect(mapStateToProps, mapDispatchToProps)(UpdateAnomaliePositionPage);