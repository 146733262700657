import AssetInformation from "../AssetInformation";
import CardGraphic from "../CardGraphic";
import GraphicDegradation from "../GraphicDegradation";
import GraphicStatus from "../GraphicStatus";
import SecondaryButton from "../SecondaryButton";
import SmallButton from "../SmallButton";
import Utils from "../../../utils/utils";

import { ActionsContainer, AssetInfoWindowContainer, TopContainer } from "./styled";
import { useAssetInfoWindowHook } from "./hook";

import plus_icon from "../../../assets/icons/plus.svg";
import { convertNumberBR } from "../../../utils/functions";
import NewRegisterModal from "../Modals/NewRegisterModal";


const AssetInfoWindow = ({asset})=>{
    const hook = useAssetInfoWindowHook({asset});

    return(
        <AssetInfoWindowContainer>
            <TopContainer>
                <AssetInformation asset={asset}/>
            </TopContainer>

            <CardGraphic 
                title={"Status dos Ativos (m²)"} 
                footer={{description: "Área Total", value: hook.chats.metricsData?.status?.totalArea}}
                graphic={<GraphicStatus data={hook.chats.metricsData?.status} isLoading={hook.chats.isLoading}/>}
            />

            <CardGraphic 
                title={"Degradação de RI's (m²)"} 
                footer={{description: "Área", value: hook.chats.metricsData?.degradationRis?.total}}
                graphic={<GraphicDegradation  data={hook.chats.metricsData?.degradationRis} isLoading={hook.chats.isLoading} />}
            />

            <ActionsContainer>
                {/* <SmallButton onClick={()=>hook.general.setShowRegisterModal(true)}>
                    <img src={plus_icon} alt="Icone de adicionar"/>
                </SmallButton> */}
                <SecondaryButton className={'secondary-button'} onClick={()=>hook.general.navigate(`/asset/${asset.id}`)}>Detalhes do Ativo</SecondaryButton>
            </ActionsContainer>

            {hook.general.showRegisterModal &&
                    <NewRegisterModal
                        fkAssetId={asset.id}
                        onClose={() => hook.general.setShowRegisterModal(false)}
                    />
            }
        </AssetInfoWindowContainer>
    )
}

export default AssetInfoWindow;