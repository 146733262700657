import styled from "styled-components";

export const AnomaliesContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 94px;
    table{
        width: 1080px;
        border-collapse: collapse;

        
        th{
            border-bottom: 1px solid #A8A8A8;
            height: 40px;
            text-align: start;
        }
        tbody{
            font-weight: 500;
        }

        .check{
            text-align: start;
        }

        td{
            height: 32px;
            margin-bottom: 16px;
        }
    }
`;

export const AnomaliesFilters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
    gap: 24px;

    .search{
        width: 190px;
        height: 24.222px;
        background-color: #E6E4E0;
        padding: 4px 8px;
    }
`;