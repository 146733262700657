import { FontGreyDarkBold, FontInputGreyDarkRegular, FontRedBold, FontRedRegular } from "../../../../../utils/typografyApp";
import Guide from "../../../../components/Guide";
import { ExitContainer, HomePageContainer, HomePageHeader } from "../styled";
import exit_icon from '../../../../../assets/icons/exit.svg';
import { AnomaliesContainer } from "./styled";
import { useAnomaliesHook } from './hook';
import { Loading } from "../../../../components/Loading";
import edit_anomaly_icon from '../../../../../assets/icons/edit_anomaly.svg';
import check_icon from '../../../../../assets/icons/check.svg';
import { formatDate } from "date-fns";
import search_icon from '../../../../../assets/icons/search.svg';
import { Tab3DFilters } from "../Tab3D/styled";
import Input from "../../../../components/Input";

export const AnomaliesPage = () => {

    const hook = useAnomaliesHook()

    return (
        <HomePageContainer>
            <div className="anomalies-content">
                <HomePageHeader>
                    <Guide page='Anomalias' />

                    <ExitContainer>
                        <img src={exit_icon} alt="Icone de sair" />
                        <FontInputGreyDarkRegular>Sair</FontInputGreyDarkRegular>
                    </ExitContainer>
                </HomePageHeader>

                <AnomaliesContainer>
                    <FontGreyDarkBold>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={2}>Ativo</th>
                                    <th>Sessão</th>
                                    <th>ID</th>
                                    <th>Data</th>
                                    <th>Tipo</th>
                                    <th colSpan={2}></th>
                                </tr>

                            </thead>

                            <tbody>
                                {hook.anomalies.anomaliesData?.count === 0 || hook.anomalies.isLoading ?
                                    <tr>
                                        <td colSpan={8} className="empty-table">
                                            {hook.anomalies.isLoading ?
                                                <Loading /> :
                                                <div>Nenhum usuário cadastrado</div>
                                            }
                                        </td>
                                    </tr> :
                                    <>
                                        <tr>
                                            <td colSpan={8}>
                                                <Tab3DFilters>
                                                    <Input className={'search'} icon={search_icon} placeholder={'Buscar ativo'} setSearch={hook.anomalies.setSearch} />
                                                </Tab3DFilters>
                                            </td>
                                        </tr>
                                        {hook.anomalies.anomaliesData?.rows?.map(anomaly => (
                                            <tr className={anomaly.position ? '' : 'click'} onClick={anomaly.position ? () => { } : () => hook.navigate(`/update-anomaly/${anomaly.id}`)}>
                                                <td>{anomaly.section?.asset?.name}</td>
                                                <td><img src={anomaly.position ? check_icon : edit_anomaly_icon} /></td>
                                                <td>{anomaly.section?.name}</td>
                                                <td>#{anomaly.internalId}</td>
                                                <td>{formatDate(anomaly.date, 'dd/MM/yyyy')}</td>
                                                <td>{anomaly.defectType?.name}</td>
                                                <td><FontRedBold>{anomaly.degradationDegree?.name}</FontRedBold></td>
                                                <td>{
                                                    anomaly.prematureFailure ?
                                                        <FontRedRegular>Falha Prematura</FontRedRegular> :
                                                        <span>Falha Não Prematura</span>
                                                }
                                                </td>
                                            </tr>
                                        ))}

                                    </>
                                }

                            </tbody>
                        </table>
                    </FontGreyDarkBold>

                </AnomaliesContainer>
            </div>
        </HomePageContainer>
    );
}