import { z } from "zod";
import { fkSchema, fkSchemaOptional, generalMessageError, mediasSchema } from "./config";

export const anomalieSchema = z.object({
    internalId: z.string().min(1, { message: generalMessageError }),
    date: z.string().min(1, { message: generalMessageError }),
    area: z.union([
        z.string().min(1, { message: generalMessageError }),
        z.number().min(0, { message: generalMessageError }),
    ]),
    prematureFailure: z.boolean().default(false),
    position: z.string().min(1, { message: generalMessageError }),
    fkDegradationDegreeId: fkSchema,
    fkDefectTypeId: fkSchema,
    fkSectionsId: fkSchemaOptional,
    medias: z.union([
        z.array(
            z.object({
                path: z.string()
            })
        ),
        mediasSchema
    ])
});