import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { AdmHomePage } from "./ui/modules/Adm/HomePage";
import { LocalClientToken } from "./utils/local-client-token";
import { LocalAdmToken } from "./utils/local-adm-token";

import LoginPage from "./ui/modules/LoginPage";
import HomePage from "./ui/modules/HomePage";
import AssetPage from "./ui/modules/AssetsPage";
import SectionPage from "./ui/modules/SectionPage";
import UpdateAnomaliePosition from "./ui/modules/Adm/HomePage/UpdateAnomaliePosition";
import { AnomaliesPage } from "./ui/modules/Adm/HomePage/AnomaliesPage";

const ProtectedRoute = ({ token, children }) => {
  if (!token) {
    return <LoginPage isAdmin={false} />;
  }
  return children;
};

const ProtectedAdmRoute = ({ admToken, children }) => {
  if (!admToken) {
    return <LoginPage isAdmin={true} />;
  }
  return children;
};

const RoutesApp = () => {
  const token = LocalClientToken.getStoredToken();
  const admToken = LocalAdmToken.getStoredToken();

  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/login" element={<LoginPage isAdmin={false} />} />
          <Route path="/" element={<ProtectedRoute token={token}><HomePage /></ProtectedRoute>} />
          <Route path="/asset/:id" element={<ProtectedRoute token={token}><AssetPage /></ProtectedRoute>} />
          <Route path="/section/:id" element={<ProtectedRoute token={token}><SectionPage /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>

      <BrowserRouter basename="/adm">
        <Routes>
          <Route path="/login" element={<LoginPage isAdmin={true} />} />
          <Route path="/" element={<ProtectedAdmRoute admToken={admToken}><AdmHomePage /></ProtectedAdmRoute>} />
          <Route path="/update-anomaly/:id" element={<ProtectedRoute token={token}><UpdateAnomaliePosition /></ProtectedRoute>} />
          <Route path="/anomalies" element={<ProtectedRoute token={token}><AnomaliesPage /></ProtectedRoute>} />
          
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesApp;
