import { useNavigate, useParams } from "react-router-dom";
import { sectionsQueries, useSectionsService } from "../../../../../services/sectionService";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import { baseUrl } from "../../../../../service/api";
import Utils from "../../../../../utils/utils";
import img_icon from "../../../../../assets/icons/images_border.svg";
import { getFromIndexedDB, saveToIndexedDB } from "../../../../../service/indexedDB";
import { blobToArrayBuffer } from "../../../../../utils/functions";
import axios from "axios";
import { assetsQueries, useAssetsService } from "../../../../../services/assetService";
import { anomaliesQueries, useAnomaliesService } from "../../../../../services/anomalieService";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { anomalieSchema } from "../../../../../models/schemas/anomalieSchema";
import { formatDate } from "date-fns";

export function useUpdateAnomaliePositionHook() {
    const sectionService = useSectionsService();
    const assetService = useAssetsService();
    const anomaliesService = useAnomaliesService();
    const [isWaiting, setIsWaiting] = useState(false);


    const [objects, setObjects] = useState([]);
    const [highlightedObject, setHighlightedObject] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const orbitControls = useRef();
    const { id } = useParams();
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [opacity, setOpacity] = useState(false);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const camera = useRef();
    const [sphere, setSphere] = useState();
    const [size, setSize] = useState();
    const [scaleFactor, setScaleFactor] = useState();

    const {
        data: anomalyData,
        isLoading: isLoadingAnomaly,
        isRefetching: isRefetchingAnomaly,
        refetch: refetchAnomaly
    } = useQuery(anomaliesQueries.GET_BY_ID, () => anomaliesService.getAnomaliesById({ id }));

    const { mutate, isLoading } = useMutation((payload) =>
        anomaliesService.editAnomalies({ payload: { ...payload, id: anomalyData.id }, onSuccess })
    );

    const {
        data: sectionData,
        isLoading: isLoadingSection,
        isRefetching: isRefetchingSection,
        refetch: refetchSection
    } = useQuery(sectionsQueries.GET_BY_ID, () => sectionService.getSectionsById({ id: anomalyData?.fkSectionsId }));

    const {
        data: assetData,
        isLoading: isLoadingAsset,
        isRefetching: isRefetchingAsset,
        refetch: refetchAsset
    } = useQuery(assetsQueries.GET_BY_ID, () => assetService.getAssetsById({ id: sectionData?.fkAssetId }));

    const anomalieForm = useForm({
        resolver: zodResolver(anomalieSchema),
        defaultValues: anomalyData ? { ...anomalyData, date: formatDate(anomalyData.date, 'yyyy-MM-dd'), medias: [] } : {}
    });

    function onSuccess() {
        navigate('/anomalies');
    }

    const watchPosition = anomalieForm.watch('position');
    const watchArea = anomalieForm.watch('area');

    function handleObjectClick(event) {
        const intersects = event.intersections;
        if (intersects?.length > 0) {
            const intersect = intersects[0];
            const { point } = intersect;
            
            // Inicia a espera
            setIsWaiting(true);
    
            if (sphere) {
                sphere.position.copy(point);
                anomalieForm.setValue('position', JSON.stringify(sphere.position));
    
                // Finaliza a espera após processar
                setTimeout(() => setIsWaiting(false), 500);
            } else {
                const objectArea = size.x * size.y; // Área do objeto
                const anomalyArea = (parseFloat(watchArea) * objectArea) / parseFloat(sectionData?.totalArea);
                const sphereRadius = 200 * scaleFactor;
    
                const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
                const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 });
                const newSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                newSphere.position.copy(point);
    
                setObjects((prev) => {
                    if (prev.find(object => object.section === `sphere${prev.length - 1}`)) {
                        return prev;
                    }
                    setSphere(newSphere);
                    return [...prev, { section: `sphere${prev.length}`, object: newSphere }];
                });
                anomalieForm.setValue('position', JSON.stringify(newSphere.position));
    
                // Finaliza a espera após processar
                setTimeout(() => setIsWaiting(false), 500);
            }
        }
    }

    useEffect(() => {
        if (sectionData)
            refetchAsset();
    }, [sectionData]);

    useEffect(() => {
        anomalieForm.reset(anomalyData);
        if (anomalyData?.fkSectionsId) {
            refetchSection();
        }
    }, [anomalyData]);



    useEffect(() => {
        if (sectionData?.reference3d && assetData?.reference3d) {

            Utils.clear3DObjects(objects, setObjects);

            const tempObjects = [];

            if (sectionData?.reference3d?.section3d?.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const relativePaths = sectionData?.reference3d?.section3d.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        let arrayBuffer;

                        const cachedDataBlob = await getFromIndexedDB(cacheKey, `home-${assetData?.reference3d?.fkAsset3DItemId}`);

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${sectionData?.reference3d?.section3d?.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgress((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            arrayBuffer = response.data;
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer], `home-${assetData?.reference3d?.fkAsset3DItemId}`));
                        }

                        if (arrayBuffer) {

                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            let size = new THREE.Vector3();
                            setSize(size);
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            setScaleFactor(scaleFactor);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: "#cccccc" });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            // Adicionar esferas para anomalias
                            // if (sectionData.anomalies) {
                            //     sectionData.anomalies.forEach(anomaly => {
                            //         if (!anomaly.isResolved) {
                            //             let { position } = anomaly;

                            //             if (position.includes('\"x\":')) {
                            //                 position = JSON.parse(position);
                            //                 const objectArea = size.x * size.y; // Área do objeto

                            //                 const anomalyArea = (parseFloat(anomaly.area) * objectArea) / parseFloat(sectionData.totalArea); // Área da anomalia
                            //                 const sphereRadius = 200 * scaleFactor
                            //                 // const sphereRadius = sphereRadius / Math.sqrt(objectArea / Math.PI) / 8; // Fator de escala baseado na área do objeto

                            //                 const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
                            //                 const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 });
                            //                 const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

                            //                 sphere.position.set(position.x, position.y, position.z);

                            //                 tempObjects.push({ anomaly, object: sphere });

                            //                 if (anomaly.medias && anomaly.medias.length > 0) {
                            //                     const textureLoader = new THREE.TextureLoader();
                            //                     textureLoader.load(img_icon, (texture) => {
                            //                         const iconGeometry = new THREE.PlaneGeometry(0.05, 0.05); // Ajustar o tamanho conforme necessário
                            //                         const iconMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
                            //                         const icon = new THREE.Mesh(iconGeometry, iconMaterial);

                            //                         const boundingBox = new THREE.Box3().setFromObject(stlMesh);

                            //                         // Calcular deslocamentos
                            //                         let offsetX = 0, offsetY = 0, offsetZ = 0;
                            //                         const offset = sphereRadius + 0.02;

                            //                         if ((sphere.position.x - sphereRadius) < boundingBox.min.x) {
                            //                             offsetX = -offset; // Esquerda
                            //                         } else if ((sphere.position.x + sphereRadius) > boundingBox.max.x) {
                            //                             offsetX = offset; // Direita
                            //                         }

                            //                         if ((sphere.position.y - sphereRadius) < boundingBox.min.y) {
                            //                             offsetY = -offset; // Inferior
                            //                         } else if ((sphere.position.y + sphereRadius) > boundingBox.max.y) {
                            //                             offsetY = offset; // Superior
                            //                         }

                            //                         if ((sphere.position.z - sphereRadius) < boundingBox.min.z) {
                            //                             offsetZ = -offset; // Traseiro
                            //                         } else if ((sphere.position.z + sphereRadius) > boundingBox.max.z) {
                            //                             offsetZ = offset; // Frontal
                            //                         }

                            //                         icon.position.set(sphere.position.x + offsetX, sphere.position.y + offsetY, sphere.position.z + offsetZ);
                            //                         const iconRotated = icon.clone();
                            //                         iconRotated.rotation.set(0, Math.PI, 0);
                            //                         tempObjects.push({ anomaly, object: icon }, { anomaly, object: iconRotated });

                            //                         setObjects([...tempObjects]);
                            //                     });
                            //                 }
                            //             }
                            //         }
                            //     });
                            // }

                            tempObjects.push(
                                { section: sectionData.reference3d.section3d, object: stlMesh },
                            );

                            setObjects(tempObjects);

                            setRefresh(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${sectionData?.reference3d?.section3d?.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [sectionData, assetData, anomalyData]);

    function onSubmit(payload) {
        delete payload.medias;
        mutate({ ...payload, fkSectionsId: sectionData?.id });
    }


    return {
        form: {
            onSubmit,
            anomalieForm,
            isLoading
        },
        general: {
            opacity,
            setOpacity,
            showRegisterModal,
            setShowRegisterModal,
            navigate,
            id
        },
        section: {
            sectionData,
            isLoading: isLoadingSection || isRefetchingSection || refresh || isLoadingAsset || isRefetchingAsset,
            refetchSection
        },
        anomaly: {
            anomalyData,
            isLoadingAnomaly,
            watchPosition
        },
        config3d: {
            handleObjectClick,
            orbitControls,
            objects,
            highlightedObject,
            progress,
            camera
        }
    }
}
